import "./statbanner.scss";

const StatBanner = ({ stats, descriptions }) => {
  const statss = ["10,000+", "5,000+", "150+", "$400,000+", "30+", "6+ Years"];
  const descriptionss = [
    "beneficiaries impacted through the power of chess",
    "Children empowered through Lifeline Education & Empowerment",
    "Community Members",
    "In academic scholarships",
    "African countries impacted",
    "of Unveiling the potential and royalty in every child",
  ];

  return (
    <section id="stat__banner">
      <img
        src={
          "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/StatAfrica_E29fxFabP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616768045"
        }
        className="stat__africa"
        alt="africa-chess-bg"
      />
      <div className="stat__banner__content">
        <div className="stats">
          {statss?.map((s, i) => (
            <div className="stat" key={i}>
              <p className="value">{s}</p>
              <p className="desc">{descriptionss[i]}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StatBanner;

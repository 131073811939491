import { AiOutlineClose } from "react-icons/ai";
import { FaHeart } from "react-icons/fa";
import { useRef, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import styledComponents from "styled-components";
import { clickOutside } from "../../utils";
const ModalDiv = styledComponents.div`
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    padding :0.5rem;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 500ms ease-in-out, opacity 600ms ease-in-out;
    opacity: 0;
    // transform: translateX(100%);
    pointer-events: none;
    .modal__conatiner{
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 100%;
        height: 100%;
        .modal_card{
            min-width: 100px;
            max-height: 90vh;
            overflow:auto;
            position: relative;
            transform: scale(0);
            transition: all 500ms 100ms ease-in-out;
            max-width: ${(props) => props.maxWidth || "900px"};
            background: var(--white);
            box-shadow: 0px 5px 14px 3px rgba(0, 0, 0, 0.09);
            border-radius: 20px;
            padding : ${(props) => props.padding || "2rem"};
            .close{
                z-index: 20;
                top: 0;
                right: 0;
                padding: 1.5rem; 
                position: absolute;
                display: flex;
                justify-content: flex-end;
                span{
                    display: flex;
                    padding: .5rem;
                    cursor: pointer;
                    border-radius: 50%;
                    border: 1px solid rgba(0,0,0,0.3);
                    &:hover{
                        background-color: rgba(0,0,0, 0.1);
                    }
                    @media screen and (max-width : 800px){
                        width: 30px;
                        height: 30px;
                    }
                }
            }
            .cb{
                margin-top: 1rem;
                margin-bottom: 1rem;
                display: flex;
                gap: 1rem;
                flex-wrap: wrap;
                justify-content: center;
                .img{
                    width: 300px;
                    height: 276px;
                    flex-shrink: 0;
                    border-radius: 12px;
                }
                .carousel {
                  display: none;
                }
                @media screen and (max-width : 720px){
                    .img{
                        display: none;
                    }
                    .carousel {
                      display: flex;
                      width: 100%
                    }
                    .img1{
                        width: 300px;
                        height: 276px;
                        flex-shrink: 0;
                        border-radius: 12px;
                    }
                }
            }
        }
        .header{
            font-size: 1.2rem;
            font-weight: 700;
            margin-bottom: 1rem;
            text-align: left;
            color: #282828;
        }
        .children{
            font-size: 1rem;
            font-weight: 400;
            color: #282828;
            text-align: left;
            line-height: 1.5;
            margin-bottom: 1rem;
        }
        .linkText {
           color: #4495D0;
           font-size: 14px;
           font-style: normal;
           font-weight: 600;
           line-height: 30px;
           text-decoration-line: underline;
        }
        .bottom {
            display: flex;
            gap: 1rem;
            margin-top: 1rem;
            }
        .btn1 {
            display: inline-flex;
            gap: 0.2rem;
            width: 13rem;
            height: 3rem;
            align-items: center;
            justify-content: center;
            border-radius: 43px;
            border: 1.5px solid #000;
            background: #000;
            color: #fff;
            cursor: pointer;
        }
        .btn2 {
            width: 13rem;
            height: 3rem;
            border-radius: 43px;
            border: 1.5px solid #000;
            cursor: pointer;
        }
       
        }
        
    }
    
    ${(props) =>
      props.open &&
      `
            opacity: 1;
            transform: translateX(0%);
            .modal__conatiner{
                .modal_card{
                    transform: scale(1);
                }
            }
            pointer-events:auto;
        `}
    }
`;
const Modal = ({
  open,
  close,
  children,
  iconVisible,
  btnText,
  images,
  ...props
}) => {
  const modalRef = useRef();
  const outerModal = useRef();
  useEffect(() => {
    if (modalRef.current && outerModal.current) {
      return clickOutside(outerModal.current, modalRef.current, () =>
        close?.()
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalRef, outerModal]);

  return (
    <ModalDiv open={open} ref={outerModal} {...props}>
      <div className="modal__conatiner">
        <div className="modal_card" ref={modalRef}>
          {images?.length > 0 && (
            <div className="cb">
              <Carousel
                centerMode={true}
                centerSlidePercentage={100}
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
                useKeyboardArrows={true}
                className="carousel"
              >
                {images.map((image, index) => (
                  <img key={index} src={image} alt="modal" className="img1" />
                ))}
              </Carousel>
              {images.map((image, index) => (
                <img key={index} src={image} alt="modal" className="img" />
              ))}
            </div>
          )}
          {iconVisible && (
            <div className="close">
              <span onClick={() => close?.()}>
                <AiOutlineClose />
              </span>
            </div>
          )}
          <h1 className="header">{props.header}</h1>
          <p className="children">{children}</p>
          <p className="children">
            {props.moreChildren}{" "}
            <span>
              <Link to={`${props.link}`} className="linkText">
                {props.linkText}
              </Link>
            </span>
          </p>
          <p className="children">{props.moreChildren1}</p>
          <p className="children">{props.moreChildren2}</p>
          {!iconVisible && (
            <div className="bottom">
              <a
                href={`${props.donateLink}`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn1"
              >
                {btnText ? btnText : "Donate"} <FaHeart color="#BA8C00" />
              </a>
              <button className="btn2" onClick={() => close?.()}>
                Continue
              </button>
            </div>
          )}
        </div>
      </div>
    </ModalDiv>
  );
};

export default Modal;

import { FeaturedBanner } from '../../Components';
import './supportfeaturedbanner.scss';

const SupportFeaturedBanner = () => {
    return (
        <section id='support__featured__banner'>
            {/* <SupportBanner /> */}
            <FeaturedBanner />
        </section>
    );
};

export default SupportFeaturedBanner;

import { Hero } from "../../Components";

const ScholarshipHero = ({ text, image }) => {
  return (
    <Hero
      id="scholarship__hero"
      // title={text ?? "Joining Hands for Education and Empowerment"}
      title={"Joining Hands for Education and Empowerment"}
      buttonText={"Be Part of the Circle to Sponsor a Child"}
      sectionId="#chaperone__form"
      background={`linear-gradient( 90.35deg, rgba(34, 34, 34, 0.76) 26.34%,	rgba(57, 57, 57, 0) 99.71% ),
		url(${
      image ||
      "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/DonteHero_Zoq3Bd3DK.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616802936"
    })`}
    />
  );
};

export default ScholarshipHero;

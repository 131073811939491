import HowWeStarted from "./how-we-stated.txt";
import MeetChampJourneyAdeoye from "./meet-the-champ-journey.txt";
import MeetChampJourneyFerdinard from "./meet-the-champ-journey-ferdinard.txt";
import ChessFilaLaunched from "./chess-fila-launched.txt";
import ChessForEducation from "./chess-for-education.txt";
import ChessHealth from "./cisa-health.txt";

const blogsData = [
  {
    id: 0,
    textPath: HowWeStarted,
    title: "How we started: The Majidun Chess Dream",
    imgUrl:
      "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40,w-900,h-431,fo-custom,cm-extract/BlogImage1_y9-yl0DKNth.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616794762",
    imgThumbnail:
      "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/BlogImage1_y9-yl0DKNth.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616794762",
    slug: "/blog/how-we-started-the-majidun-chess-dream",
    excerpts: `
		The Chess in slums Africa Initiative commonly known as CISA was established on September 1st 2018 at Majidun Ikorodu, 
		Lagos Nigeria to lift children out of marginalized community
		`,
  },
  {
    id: 1,
    textPath: MeetChampJourneyAdeoye,
    title: "Meet The Champ (Oshodi Chess Journey): Adeoye Fawas",
    imgUrl:
      "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40,w-900,h-500,fo-custom,cm-extract/BlogImage2_rwM8BZjkO.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616801280",
    imgThumbnail:
      "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/BlogImage2_rwM8BZjkO.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616801280",
    slug: "/blog/meet-the-champ-journey-adeoye-fawas",
    excerpts: `
		A man once said “the brightest lights are found in the thickest darkness”. 
		At Chess in Slums, Africa, we believed in that statement when we met Adeoye Fawas, our current Chess
		`,
  },
  {
    id: 2,
    textPath: MeetChampJourneyFerdinard,
    title: "Meet The Champ (Makoko Chess Journey): Ferdinard Maumo",
    imgUrl:
      "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40,w-900,h-500,fo-custom,cm-extract/BlogImage3_sLwsNYbt2C.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616799603",
    imgThumbnail:
      "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/BlogImage3_sLwsNYbt2C.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616799603",
    slug: "/blog/meet-the-champ-journey-ferdinard-maumo",
    excerpts: `
		The 40-week programme offers fun
		and enriching chess trainings for out-of-school and disadvantaged children
		in the slum at all levels of experience
		`,
  },
  {
    id: 3,
    textPath: ChessFilaLaunched,
    title: "ChessFila HAS LAUNCHED AND IS READY FOR TAKEOFF!!",
    imgUrl:
      "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/BlogImage4_cYdFe9nlzn1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644650256929",
    imgThumbnail:
      "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/BlogImage4_cYdFe9nlzn1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644650256929",
    slug: "/blog/chess-fila-has-launched-and-is-ready-for-takeoff",
    excerpts: `
		Hi there! Chess In Slums has officially launched its recently acclaimed signature item, Chess Fila. 
		In an effort to reach our Global Fundraising Campaign goal of one million dollars, 
		we decided to release an item we believe
		`,
  },
  {
    id: 4,
    textPath: ChessForEducation,
    title: "CHESS FOR EDUCATION",
    imgUrl:
      "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/BlogImage5_UYVvqvsIq.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644650255488",
    imgThumbnail:
      "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/BlogImage5_UYVvqvsIq.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644650255488",
    slug: "/blog/chess-for-education",
    excerpts: `
		Join a team of game changers who are changing the narrative of the Nigerian child living in slum communities like Oshodi. 
		The goal of the Chess in Slums, Africa is to enroll 1000 children in schools and you can be a part of it. 
		`,
  },
  {
    id: 5,
    textPath: ChessHealth,
    title: "Checkmating Health Challenges in Makoko",
    imgUrl:
      "https://res.cloudinary.com/dtgoamhgo/image/upload/v1729571621/uln1zaajut1xdlbdlacg.jpg",
    imgThumbnail:
      "https://res.cloudinary.com/dtgoamhgo/image/upload/v1729571506/abvw7q9rcsxp5p5dpth6.jpg",
    slug: "/blog/check-mating-health-challenges-in-makoko",
    excerpts: `
		The recent loss of a young student in Makoko, Mathew in December 2023 due to preventable illnesses...
		`,
  },
];

export default blogsData;

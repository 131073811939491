import AppContext from "../../context/app.context";
import { useContext } from "react";
import "./featuredbanner.scss";

const FeaturedBanner = () => {
  const { homeFeatures: suspender } = useContext(AppContext);
  const data = suspender?.read() || {};
  const { docs } = data;

  const newDocs = [...docs];
  newDocs[6] = {
    image:
      "https://res.cloudinary.com/dtgoamhgo/image/upload/unnamed_zg6wxw.png",
    url: "https://www.premiumtimesng.com/news/headlines/502437-fawaz-story-of-18-year-old-chess-champion-who-emerged-from-oshodi-slum.html",
  };

  return (
    <div id="featured__banner">
      <p className="title">We are featured on</p>

      {newDocs?.map((link, i) => (
        <a href={link.url} target="_blank" rel="noreferrer" key={i}>
          <img src={link.image} alt="BBC" />
        </a>
      ))}
    </div>
  );
};

export default FeaturedBanner;

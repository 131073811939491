// import AboutHeroImage from "../../assets/AboutHeroImage.png";
// import { splitIntoParagraphs } from "../../utils";
import { useNavigate } from "react-router-dom";
import "./abouthero.scss";
import { Button } from "../../Components";

const AboutHero = ({ visionIntro, visionBody, visionImage }) => {
  const navigate = useNavigate();
  //   let paragraphs = splitIntoParagraphs(visionBody);

  return (
    <section id="about__hero">
      <div className="about__hero__content">
        <h1 className="title">Founder’s Vision</h1>
        <h2 className="sub__title">
          To empower, uplift, and educate 1 million children in marginalized
          communities across Africa over the next decade, using chess as a focal
          point.
        </h2>

        <div className="image__text">
          <div className="text__container">
            {/* {paragraphs.map((pag, id) => (
              <p key={id}>{pag}</p>
            ))} */}

            <p>
              Chess in Slums Africa (CISA) is a non-profit organization
              dedicated to uplifting children from underserved communities
              through the strategic use of chess, STEM education, and
              socio-emotional development. Founded in 2018 by Tunde Onakoya,
              CISA began with the simple goal of teaching chess to children in
              slum communities as a means to enhance their cognitive and life
              skills. Over the years, our organization has evolved to include
              comprehensive educational programs that now integrate robotics,
              AI, and digital literacy alongside chess education.
            </p>

            <p>
              Our programs are designed to provide holistic support to children,
              addressing not only their educational needs but also their social
              and emotional well-being. By offering scholarships, vocational
              training, and mentorship, we ensure that our beneficiaries receive
              continuous support until they become productive, active, and
              responsible members of society. CISA operates in several slum
              communities across Nigeria, with a focus on creating safe,
              inclusive, and empowering learning environments.
            </p>
          </div>

          <div className="image__container">
            {/* <img src={visionImage + "/tr:q-50,w-466"} alt="founders-bg" /> */}

            <iframe
              title="Chess in Slums teams and Volunteers"
              src="https://www.youtube.com/embed/VCy0B7i26og?si=jG97gzKbOlqEwkqi"
            //   width="460"
              height="515"
              frameBorder="0"
            ></iframe>
          </div>
        </div>

        <Button
          text={"HOW TO PARTNER"}
          className={"button__primary learn__more"}
          onClick={() => navigate("/partner")}
        />
      </div>
    </section>
  );
};

export default AboutHero;

import "./homevideo.scss";

const HomeVideo = ({ video }) => {
  return (
    <section id="home__video">
      <div className="home__video__content">
        <div className="team__video__container">
          <iframe
            title="Chess in Slums teams and Volunteers"
            src="https://www.youtube.com/embed/w_-PzPMdGcg?si=W1D-4mrkGxRg-uSb"
            // src={video}
            width="560"
            height="315"
            frameBorder="0"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default HomeVideo;

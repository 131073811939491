// import { splitIntoParagraphs } from "../../utils";

import "./ouridentity.scss";

const OurIdentity = ({ identityText1, identityText2, identityImage }) => {
  //   let paragraphs = splitIntoParagraphs(identityText2);

  return (
    <section id="our__identity">
      <div className="our__identity__content">
        <h1 className="title">Our Identity</h1>
        <p className="follow__up__text">
          Achieving our goals and making a profound impact requires us to
          explore uncharted territories and transform small beginnings into
          significant successes. This approach has become our defining strength.
        </p>

        <div className="image__text">
          <img src={identityImage + "/tr:q-80,w-392"} alt="our-identity" />
          <div className="text__container">
            {/* {paragraphs.map((pag, id) => (
              <p key={id}>{pag}</p>
            ))} */}

            <p>
              We draw inspiration from the powerful principles of chess. Just as
              the Pawn—often seen as the weakest piece—can be promoted to a
              Queen, the most formidable piece, upon reaching the 8th rank, we
              believe every child has the potential to rise from their
              disadvantaged circumstances to a position of strength and
              opportunity.
            </p>

            <p>
              At Chess in Slums, we see ourselves as the bridge that connects
              these children to their highest potential, enabling them to
              achieve meaningful status and success. For us, it’s about
              empowering every child with the opportunity to dream big and reach
              for a brighter future.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurIdentity;

import { useNavigate } from "react-router-dom";
import PawnIcon from "../../assets/svgs/PawnIcon";
import { Button } from "../../Components";
import "./losingpotential.scss";

const LosingPotential = ({ image, title, facts, description }) => {
  const navigate = useNavigate();

  const factss = [
    "According to the Harmonized Nigeria Living Standard Survey (HNLSS), 70% of Nigerian children live in poverty, with 23% facing extreme poverty.",
    "As of 2024, UNICEF reports that the number of out-of-school children in Nigeria has surged to 18.3 million.",
    "Children living in slums frequently experience stigma and discrimination, which limits their access to education.",
    "With nearly half of the population under the age of 15, tackling child poverty is crucial to breaking the cycle of poverty across the nation.",
  ];

  return (
    <section id="losing__potential">
      <div className="losing__potential__content">
        <div className="left__text">
          <h1 className="title">
            We are losing Potential Kings and Queens in the Slums...
          </h1>
          <p className="text">{description}</p>

          {factss?.map((f, i) => (
            <div className="point" key={i}>
              <div className="pawn__icon">
                <PawnIcon />
              </div>
              <p className="text">{f}</p>
            </div>
          ))}
          <Button
            text={"LEARN MORE"}
            className={"button__primary learn__more"}
            onClick={() => navigate("/about")}
          />
        </div>
        <img src={`${image}/tr:q-60`} alt="potential bg" />
      </div>
    </section>
  );
};

export default LosingPotential;

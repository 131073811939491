import Button from "../Button/index.jsx";
import styledComponents from "styled-components";

const HeroSection = styledComponents.section`
	margin-top: 5.63rem; // 3.87rem for mobile
	height: calc(100vh - 5.63rem);
	width: 100%;
    background: ${(props) => props.background};
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 80% 50%;
	@media screen and (max-width: 1000px) {
		margin-top: 3.87rem;
		min-height: calc(100vh - 3.87rem);
	}
	@media screen and (max-width: 1200px) {
		padding-left: 1.56rem;
		padding-right: 0.5rem;
	}
	.container {
		height: 100%;
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-flow: column;
		justify-content: center;
        .title {
            font-family: "New York Extra Large";
            font-style: normal;
            font-weight: 600;
            max-width: 44.37rem;
			color: var(--white);
			font-size: 72px;
			padding-bottom: 3rem;
			line-height: 120%;
			@media screen and (max-width: 1000px) {
				max-width: 80%;
				font-size: 3rem;
			}
		}
		.description {
			font-family: 'Kumbh Sans';
			font-style: normal;
			font-weight: normal;
			font-size: 22px;
			color: #ffffff;
			line-height: 150%;
			padding-bottom: 2rem;
			@media screen and (max-width: 1000px) {
			}
		}
		.donate_btn {
            border: none;
			border-radius: 43px;
			height: 60px;
			padding: 1rem 2rem;
			border: 3px solid var(--primary-two);
			transition: color 200ms ease-in-out, background 200ms ease-in-out;
			background-color: var(--primary-two);
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.19em;
            font-size: 16px;
            text-transform: uppercase;
            &:hover,
			&:focus {
			    background: none;
                color: var(--primary-two);
            }
            color: var(--white);
            text-decoration: none;
		}
	}
`;

const Hero = ({
  id,
  title,
  description,
  background,
  sectionId,
  buttonText,
}) => {
  return (
    <HeroSection id={id} background={background}>
      <div className="container">
        <div className="title">{title}</div>
        {description && <div className="description">{description}</div>}
        <div>
          <a href={sectionId} className={"donate_btn"}>
            {buttonText}
          </a>
        </div>
      </div>
    </HeroSection>
  );
};

export default Hero;

// import { splitIntoParagraphs } from "../../utils";
import "./sustainableimpact.scss";

const SustainableImpact = ({ title, description }) => {
  //   const paragraphs = splitIntoParagraphs(description);

  return (
    <section id="sustainable__impact">
      <div className="sustainable__impact__content">
        <h1 className="title">
          OUR APPROACH TO SUSTAINABLE IMPACT : It is possible to do great things
          from a small place
        </h1>

        {/* {paragraphs.map((p, i) => (
          <p key={i}>{p}</p>
        ))} */}

        <p>
          At <span>Chess in Slums Africa (CISA),</span> we believe that every
          child, regardless of their background, has the potential to break this
          cycle of poverty.
        </p>

        <p>
          CISA's solution lies in empowering these children through an
          innovative educational approach, using{" "}
          <span> chess as a transformative tool.</span>
          By combining{" "}
          <span>Chess, STEM education, and socio-emotional development,</span>{" "}
          we provide not only intellectual and practical skills but also build
          emotional resilience in children from under-served communities.
        </p>

        <p>
          With a trauma-informed methodology, our programs offer a path toward
          sustainable change. We nurture both{" "}
          <span>potential kings and queens</span> from the slums, guiding them
          to become <span>leaders in their communities,</span> thus contributing
          to long-term poverty alleviation and social mobility.
        </p>

        <p>
          <b>WHY CHESS? - A CHESS PIECE AND A DREAM</b>
        </p>

        <p>
          <i>
            “As we continue to expand margins, we must remember that the only
            future worth creating is the one that includes all of us, especially
            children living in impoverished communities, who may never get to
            know the vast opportunities that exist in our world. ”
          </i>
        </p>
      </div>
    </section>
  );
};

export default SustainableImpact;

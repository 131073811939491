import { ActivitiesCard } from "../../Components";
// import { useContext } from "react";
// import AppContext from "../../context/app.context";

import "./ourPrograms.style.scss";

const colours = ["#F6FFF5", "#FFFCF5", "#F6F5FF", "#FFF5FF"];
const OurPrograms = () => {
  //   const { workStreams: suspender } = useContext(AppContext);
  //   const { docs: programs } = suspender?.read() || {};

  return (
    <section id="our_program">
      <div className="container">
        <div className="title">Our Program Work-stream</div>
        <p className="sub_title">
          CISA’s core initiatives are driven through C.A.S.T.L.E, encompassing
          the following focus areas:
        </p>

        <div className="activites">
          <div className="top">
            <ActivitiesCard
              image="https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/workStream/624f0734cb66ff001681cc62/image-1649346356301"
              background={colours[0]}
              title="Chess Training and Life Skills"
              content="Through chess, we engage children from marginalized backgrounds, teaching critical thinking, patience, and problem-solving skills. Our approach includes foundational chess training, advanced tactics, life skills workshops, tournaments, and educational excursions. Using chess as a transformative tool, we aim to unlock each child's potential."
            />

            <ActivitiesCard
              image="https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/workStream/624f07aacb66ff001681cc69/image-1649346474042"
              background={colours[1]}
              title="STEM/Robotics & Digital Skills"
              content="We empower our champions with robust STEM education, offering hands-on experience in robotics, virtual reality, AI, coding, graphic design, web development, and animation. Integrating these skills with chess training fosters intellectual and creative growth, preparing them for future opportunities in the digital economy."
            />
          </div>
          <div className="bottom">
            <ActivitiesCard
              image="https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/workStream/624f07eacb66ff001681cc71/image-1649346538707"
              background={colours[3]}
              title="Lifeline Education & Empowerment"
              content="This program provides scholarships, vocational training, mentorship, civic education, and financial literacy to our champions. By bridging academic knowledge with practical life skills, we empower participants to confidently pursue their goals and emerge as future leaders within their communities."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurPrograms;

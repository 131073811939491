// import { splitIntoParagraphs } from "../../utils";
import "./ourimpact.scss";

const OurImpact = ({ impactBody, impactTitle, impactImages }) => {
  //   const paragraphs = splitIntoParagraphs(impactBody);

  return (
    <section id="our__impact">
      <div className="our__impact__content">
        <h1 className="title">{impactTitle}</h1>
        <div className="image__text">
          <div className="text__container">
            {/* {paragraphs.map((pag, id) => (
                            <p key={id}>{pag}</p>
                        ))} */}

            <p>
              For Tunde, using chess as a vehicle for social change wasn’t just
              a choice—it was a conviction.
            </p>

            <p>
              This powerful vision gave rise to Chess in Slums and has driven
              transformative change, elevating the lives of countless
              underprivileged children. By harnessing chess as a catalyst for
              socioeconomic advancement,{" "}
              <span>
                we have empowered over 10,000 children from disadvantaged
                backgrounds through chess training, scholarships, and life
                skills.
              </span>
            </p>

            <p>
              Our mission is supported by dynamic partnerships with
              international and local organizations, government bodies,
              organizations, individuals, and institutions. Chess in Slums
              Africa is continuously expanding its global reach, strengthening
              community ties, and forging impactful collaborations with leaders
              in our field.
            </p>
          </div>
          <div className="image__container">
            {impactImages?.map((imgUrl, id) => (
              <img src={imgUrl + "/tr:q-80,w-392"} key={id} alt="our-impact" />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurImpact;
